@use "src/colors";

@import '~@angular/material/theming';

@import './assets/scss/style.scss';
@import '~@swimlane/ngx-datatable/index.css';
@import '~@swimlane/ngx-datatable/themes/material.css';
@import '~@swimlane/ngx-datatable/assets/icons.css';
@import 'ag-grid-community/styles/ag-grid.css';
@import 'ag-grid-community/styles/ag-theme-alpine.css';
// always include only once per project
@include mat-core();

html, body {
  width: 100%;
  height: 100%;
  margin: 0;
}
h1, h2, h3, h4 {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
h1, h2, h3 {
  color: colors.$RWE-Blue;
  font-weight: bold;
}
h4 {
  color: colors.$Gradient-Green;
}
.power-bi-frame {
  height: calc(100% - 4px) !important;
  border: none !important;
}
button {
  background-color: transparent;
  margin: 0;
  padding: 0;
  border: none;
  text-align: center;
  cursor: pointer;
}
.overview-map-country-cluster-wrapper {
  position: absolute;
}
.mat-button-wrapper {
  text-overflow: ellipsis;
  overflow: hidden;
}

.mat-button {
  margin: 0.25em !important;
  border-radius: 2.0em !important;
  width: fit-content;
  min-width: fit-content !important;
}
.mat-button.btnNeutral {
  color: colors.$RWE-Blue;
  background-color: white !important;
  border: solid thin colors.$Energy-Green;
}
.mat-button.btnPositive {
  color: white;
  background-color: colors.$RWE-Blue !important;
  border: solid thin colors.$RWE-Blue
}
.mat-button.btnNegative {
  color: white;
  background: colors.$Accent-Dark-Red !important;
  border: solid thin colors.$Accent-Dark-Red;
}
.mat-button.btnPositive .tooltip-icon, .mat-button.btnNegative .tooltip-icon {
  color: white;
}
.mat-button[disabled] {
  background: colors.$Accent-Light-Gray !important;
  color: colors.$Sand !important;
  border: thin solid colors.$Accent-Light-Gray;
  pointer-events: none;
  cursor: none;
}
.mat-dialog-actions {
  justify-content: space-between;
}
.mat-dropdown-panel-override {
  margin-top: 28px;
}
.mat-chip {
  background: colors.$Accent-Dark-Gray !important;
  color: white !important;
  margin: 0.25em !important;
  .mat-icon {
    color: white !important;
  }
}
.mat-menu-content {
  background: colors.$Accent-Dark-Gray;
  color: white !important;

  .mat-menu-item {
    color: white !important;
  }
}
.mat-error {
  color: colors.$Accent-Light-Red !important;
}
.mat-tab-label {
  height: 40px !important;
  padding-left: 8px !important;
  padding-right: 8px !important;
  min-width: fit-content !important;
  font-size: medium !important;
  font-family: 'RWESans-Medium', Roboto, "Helvetica Neue", sans-serif !important;
}
.mat-ink-bar {
  background-color: colors.$Energy-Green !important;
}
.ag-theme-alpine {
  --ag-header-foreground-color: #1d4477; // does not work when using colors.$RWE-Blue for some reason
  --ag-selected-row-background-color: #f8f8f8;
  --ag-row-hover-color: #f3f3f3;
  div.ag-row {
    font-size: medium !important;
  }
}
.ag-grid-disabled-row {
  background-color: colors.$Sand;
}
.ag-header-group-text {
  white-space: pre-wrap !important; // to wrap text in ColGroupDef header cells
  text-align: center;
}
.ag-grid-icon {
  display: flex !important;
  justify-content: center;
  align-items: center;
  cursor:pointer;
}
.ag-grid-disabled-icon {
  color: colors.$Accent-Light-Gray;
}
.ag-grid-cell-invalid {
  box-shadow: inset 0 0 4px 1px colors.$Bad !important;
}
.ag-grid-cell-changed {
  font-weight: bold !important;
  color: colors.$Energy-Green !important;
}

.loading-spinner {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: white;
  opacity: 0.8;
  z-index: 100;

  mat-progress-spinner {
    position: relative;
    top: 50%;
    left: 50%;
  }

  aside {
    position: absolute;
    top: 50%;
    left: 50%;

    * {
      position: relative;
      left: -50%;
    }
    mat-progress-spinner {
      position: relative;
      top: -100%;
      left: 0;
    }
  }
}
.google-map-pin-label {
  background-color: colors.$Sand;
  color: colors.$RWE-Blue;
  padding: 0.125em;
}
// This seems to do nothing...
.changed ::ng-deep .mat-select-value {
  color: colors.$Energy-Green !important;
}
// This seems to do nothing...
.changed ::ng-deep .mat-select-value-text {
  color: colors.$Energy-Green !important;
}
.changed ::ng-deep .mat-form-field-label {
  color: colors.$Energy-Green !important;
}
.changed {
  font-weight: bold !important;
  color: colors.$Energy-Green !important;
}

.tooltip-icon {
  display: inline-flex !important;
  min-width: 12px !important;
  min-height: 12px !important;
  max-width: 12px !important;
  max-height: 12px !important;
  width: 12px !important;
  height: 12px !important;
  margin: 2px;
  color: #757575;

  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently supported by Chrome, Edge, Opera and Firefox */
}
.warning-triangle {
  pointer-events: all;
  color: colors.$Accent-Light-Red;
  width: 24px;
  height: 24px;
  min-width: 24px;
  min-height: 24px;

  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently supported by Chrome, Edge, Opera and Firefox */
}
.dw-state-indicator {
  display: inline-block;
  width: 10px;
  height: 10px;
  min-width: 10px;
  min-height: 10px;
  margin: 2px;
  border: solid 1px transparent;
  border-radius: 50%;
  background-color: transparent;
}
.dw-state-indicator.financial-model {
  border-color: colors.$Gradient-Green;
  background-color: white;
}
.dw-state-indicator.readonly {
  border-color: colors.$Gradient-Green;
  background-color: colors.$Gradient-Green;
}

.row {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  white-space: nowrap;
}
.row-between {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  white-space: nowrap;
}
.row-left {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  white-space: nowrap;
}
.row-right {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  white-space: nowrap;
}
.wrap-row {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
}
.col {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.grow {
  flex-grow: 1;
}
.full-width {
  width: 100% !important;
}
.half-width {
  width: 50% !important;
}
.full-height {
  height: 100% !important;
}
.half-height {
  height: 50% !important;
}

.transparent {
  visibility: hidden;
  background-color: transparent;
  color: transparent;
  opacity: 0;

  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently supported by Chrome, Edge, Opera and Firefox */
}
//noinspection CssUnknownProperty
.unselectable {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently supported by Chrome, Edge, Opera and Firefox */
}

@font-face {
  font-family: 'RWESans-Regular';
  font-style: normal;
  font-weight: 400;
  src: local('RWESans-Regular'),
  local('RWESans-Regular'),
  url('/assets/fonts/RWESans-Regular.ttf') format('truetype');
  font-size: 0.8em;
}

@font-face {
  font-family: 'RWESans-Medium';
  font-style: normal;
  font-weight: 400;
  src: local('RWESans-Medium'),
  local('RWESans-Medium'),
  url('/assets/fonts/RWESans-Medium.ttf') format('truetype');
  font-size: 0.8em;
}

@font-face {
  font-family: 'Material Icons';
  font-style: normal;
  font-weight: 400;
  src: local('Material Icons'),
  local('MaterialIcons-Regular'),
  url('/assets/fonts/MaterialIcons-Regular/MaterialIcons-Regular.ttf') format('truetype');
  font-size: 0.8em;
}

.material-icons {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 24px; /* Preferred icon size */
  display: inline-block;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;

  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;

  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;

  /* Support for IE. */
  font-feature-settings: 'liga';
}

* {
  font-family: 'RWESans-Regular', Roboto, "Helvetica Neue", sans-serif;
}
//for IE 11
date-input-polyfill {
  &[data-open="true"] {
    z-index: 1001;
  }
}

$digires: (
  50: #E0F3F4,
  100: #B3E1E4,
  200: #80CDD2,
  300: #4DB9C0,
  400: #26AAB3,
  500: #009BA5,
  600: #00939D,
  700: #008993,
  800: #007F8A,
  900: #006D79,
  A100: #A7F5FF,
  A200: #74EFFF,
  A400: #41E9FF,
  A700: #27E7FF,
  contrast: (
    50: $black-87-opacity,
    100: $black-87-opacity,
    200: $black-87-opacity,
    300: $black-87-opacity,
    400: $black-87-opacity,
    500: $black-87-opacity,
    600: $black-87-opacity,
    700: $black-87-opacity,
    800: $black-87-opacity,
    900: $black-87-opacity,
    A100: $black-87-opacity,
    A200: $black-87-opacity,
    A400: $black-87-opacity,
    A700: $black-87-opacity,
  ),
);

$digires-theme-primary: mat-palette($mat-grey, 700, 300, 900);
$digires-theme-accent: mat-palette($digires, 400, 50, 50);
$digires-theme-warn: mat-palette($digires, 800);
$digires-light-theme: mat-light-theme($digires-theme-primary, $digires-theme-accent, $digires-theme-warn);
@include angular-material-theme($digires-light-theme);
