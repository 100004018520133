$RWE-Blue: #1d4477;
$Gradient-Green: #00a19f; /*New Applications*/
$Bold-Energy-green: #07a4a2;
$Energy-Green: #3ed8c3; /*Renewables*/
$Energy-Blue: #00b1eb; /*Hydroelectric*/
$Accent-Dark-Red: #b61f34;
$Accent-Light-Red: #e7343f;
$Accent-Light-Green: #5ab88f;
$Accent-Orange: #ef7d00;
$Wind-Onshore: #d4edfc;
$Sand: #e8e8e4;
$Accent-Light-Gray: #adafb1;
$Accent-Dark-Gray: #52555c;
$Wind-Onshore: #d4edfc;
$Oil-Red: #e52330;

$News-Category-Risk: $Oil-Red;
$News-Category-Highlight: $Accent-Light-Green;
$News-Category-Update: $Energy-Blue;
$News-Category-Report: $Accent-Dark-Gray;

$Bad: $Oil-Red;
$Good: $Accent-Light-Green;
$Off-White: rgba(255, 255, 255, 0.8);
$White: #fff;
$Black: rgba(0, 0, 0, 0.87);

//.ReportCommentPMD {
//  color: violet;
//}
//
//.ReportCommentTLD {
//  color: blueviolet;
//}
//
//.ReportDataValidation {
//  color: rebeccapurple;
//}
