@use '../../colors';

.d-none {
    display: none;
}

.d-flex {
    display: flex;
}

.flex-column {
    flex-direction: column;
}

.flex-wrap {
    flex-wrap: wrap;
}

.align-items-center {
    align-items: center;
}

.align-items-flex-start {
    align-items: flex-start !important;
}

.align-items-baseline {
    align-items: baseline !important;
}

.justify-content-center {
    justify-content: center;
}

.justify-content-end {
    justify-content: flex-end;
}

.justify-content-start {
    justify-content: flex-start;
}

.justify-content-space-between {
    justify-content: space-between;
}

.justify-content-space-around {
    justify-content: space-around;
}

.flex-1-1-auto {
    flex: 1 1 auto;
}

.flex-0-0-auto {
    flex: 0 0 auto;
}

.flex-1-1-100prc {
    flex: 1 1 100%;
}

.max-width-100prc {
    max-width: 100%;
}

.w-1rem {
    width: 1rem;
}

.w-100prc {
    width: 100%;
}

.w-33prc {
    width: 33.3%;
}

.w-11-5prc {
    width: 11.5%;
}

.w-13-5prc {
    width: 13.5%;
}

.w-17-5prc {
    width: 17.5%;
}

.w-32-5prc {
    width: 32.5%;
}

.w-36-5prc {
    width: 36.5%;
}

.w-90prc {
    width: 90% !important;
}

.max-w-90prc {
    max-width: 90% !important;
}

.min-width-0 {
    min-width: 0 !important;
}

.w-100-vw {
    width: 100vw;
}

.h-100-vh {
    height: 100vh;
}

.h-100prc {
    height: 100%;
}

.max-height-100prc {
    max-height: 100%;
}

.min-height-0 {
    min-height: 0;
}

.visibility-hidden {
    visibility: hidden;
}

.padding-one {
    padding: 1rem;
}

.padding-left-1rem {
    padding-left: 1rem;
}

.padding-right-2rem {
    padding-right: 2rem;
}

.border-box {
    box-sizing: border-box;
}

.color-green {
    color: #3ed8c3;
}

.color-blue {
    color: #1d4477;
}

.no-border {
    border: 0 solid;
}

.no-background {
    background: transparent;
}

.background-white {
    background: white;
}

.background-white-transparent {
    background: rgba(255, 255, 255, .5);
}

.background-sand {
    background: colors.$Sand;
}

.no-margin {
    margin: 0 !important;
}

.margin-bottom-half {
    margin-bottom: .5rem !important;
}

.margin-bottom-minus-4px {
    margin-bottom: -4px;
}

.margin-left-1rem {
    margin-left: 1rem;
}

.margin-right-1rem {
    margin-right: 1rem;
}

.no-padding {
    padding: 0;
}

.padding-2px {
    padding: 2px;
}

.padding-top-bottom-half {
    padding: .5rem 0 .5rem;
}

.padding-top-half {
    padding-top: .5rem;
}

.padding-top-one {
    padding-top: 1rem;
}

.padding-left-right-1rem {
    padding: 0 1rem 0 1rem;
}

.padding-left-right-03rem {
    padding: 0 .3rem 0 .3rem;
}

.cursor-pointer {
    cursor: pointer;
}

.font-size-half {
    font-size: .5rem;
}

.font-size-08 {
    font-size: .8rem;
}

.font-size-1rem {
    font-size: 1rem;
}

.border-top-thin-lither {
    border-top: 1px solid #f7f7f7
}

.border-bottom-1px-lither {
    border-bottom: 1px solid #f7f7f7;
}

.border-left-1px-lither {
    border-left: 1px solid #f7f7f7;
}

.border-right-1px-lither {
    border-right: 1px solid #f7f7f7;
}

.font-size-14px {
    font-size: 14px;
}

.text-align-left {
    text-align: left;
}

.text-align-center {
    text-align: center;
}

.position-relative {
    position: relative;
}

.position-fixed {
    position: fixed;
}

.z-index-2 {
    z-index: 2;
}

.break-spaces {
    white-space: break-spaces;
}

.dark-anchor-underlined {
    &:active, &:link, &:hover, &:visited {
        color: #000000;
    }
}
